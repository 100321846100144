import React, { useState } from 'react';

function PhotoGallery({ photos }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const nextPhoto = (event) => {
    event.preventDefault();
    setLoading(true);

    if (currentIndex === photos.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const onImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className={`relative ${loading ? 'loading' : ''}`}>
      {photos && photos.length > 0 ? (
        <a href="#" onClick={nextPhoto}>
          <img
            style={{
              maxWidth: '300px',
              maxHeight: '300px',
              width: '100%', // or any other value
            }}
            src={require(`../exercises/${photos[currentIndex]}`)}
            onLoad={onImageLoad}
            alt="Gallery"
          />
        </a>
      ) : (
        <p>No image</p>
      )}
    </div>
  );
}

export default PhotoGallery;
