// src/Form.js
import React, { useState } from 'react';
import Textarea from '@mui/joy/Textarea';
import styled from "@emotion/styled";
import {Checkbox, Button, Select, MenuItem, FormControl, InputLabel, FormControlLabel } from "@mui/material";
const LockedOverlay = styled.div`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(255, 255, 255, 0.8); // semi-transparent white
z-index: 10; // ensure it overlays the content
`;
function Form({ selectedPersona, onSubmit, isLoading, handlePersonaChange, persona ,isScheduleRequired,setIsScheduleRequired }) {
  const [inputType, setInputType] = useState('text');
  const [inputValue, setInputValue] = useState('');
  
  const personaOptions = [
    'Arnold Schwarzenegger',
    'Dwayne Johnson',
    'Sylvester Stallone',
    'Bruce Lee'
  ];
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(inputType, inputValue);
    setInputValue('')
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Prevent default action (newline insertion)
      event.preventDefault();

      // Your logic here
      onSubmit(inputType, inputValue);
      setInputValue('')
    }
  };
  
  return (
    <form onSubmit={handleSubmit} style={{ marginBottom: '20px'}}>
      <div>
        Ask your personal gym assistant a question.
      
      </div>
      {isLoading && (
              <LockedOverlay> <p className='spinner'></p>
              </LockedOverlay>)}
      <div>
        <Textarea value={inputValue}
        placeholder="Type a question and press Enter..."
        onKeyDown={handleKeyDown}
         onChange={(e) => setInputValue(e.target.value)}  style={{ width: '100%', marginTop: '10px'}} />
      <Button style={{ marginTop: '10px', marginLeft:'auto' , marginRight:'auto', display:'flex',justifyContent:'center'  }} type="submit" variant="contained" color="secondary" size="large">
					Ask
					</Button><FormControlLabel
  control={
    <Checkbox
      checked={isScheduleRequired}
      onClick={(event) => setIsScheduleRequired(event.target.checked)}
      name="isScheduleRequired"
      color="primary"
    />
  }
  label="Build workout schedule"
/>
           <FormControl fullWidth margin="normal">
          <InputLabel id="persona-select-label">Select Persona</InputLabel>
          <Select
            labelId="persona-select-label"
            id="persona-select"
            value={selectedPersona}
            onChange={handlePersonaChange}
          >
            {personaOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </form>
  );
}

export default Form;
