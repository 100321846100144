import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function BasicMenu() {
	const history = useNavigate();
	const [anchorEl, setAnchorEl] = useState(false);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const logOut = ()=> {
		localStorage.setItem('token', '');
		history('/home')
	}

	const login = () => {
		history('/login')
	}

	const register = () => {
		history('/register')
	}





	const handleClose = (item) => {
		switch(item){
			case 'register':
				register();
				break;
			case 'login':
				login();
				break;
			case 'logout':
				logOut();
				break;
			case 'gymbro':
				history('/gymbro');
				break;
			case 'myAccount':
			history('/myAccount');
			break;
		}
		setAnchorEl(null);
	};

	
  // Mock logic to check for token, replace with your own
  const hasToken = localStorage.getItem('token'); // Or whatever your logic is to check for a token


	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				style={{ color: "#eeeeee" }}
				onClick={handleClick}>
				<MenuIcon/>
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}>
			{!hasToken && (<MenuItem onClick={() => handleClose('register')}>
			
				Register
			
			</MenuItem>)}
			 {/* Conditionally render Login or Logout based on token presence */}
			 

        {hasToken && (
          <MenuItem onClick={() => handleClose('myAccount')}>My account</MenuItem>
        )}
		{hasToken && (
			<MenuItem onClick={() => handleClose('gymbro')}>GymG</MenuItem>
		)}
		{hasToken ? (
          <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
        ) : (
          <MenuItem onClick={()=> handleClose('login')}>
              Login
          </MenuItem>
        )}
			
			</Menu>
		</div>
	);
}
