import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import BasicMenu from "./partials/dropdownMenu";
import Typography from "@mui/material/Typography";

function NavBar() {
	const token = localStorage.getItem("token");
	console.log("token = ", token);
	return (
		<AppBar position="static" style={{ backgroundColor: "#66C3D5" }}>
			<Toolbar
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Button component={Link} to="/" style={{ color: "#eeeeee" }}>
						<Avatar
							src={`${process.env.PUBLIC_URL}/gaa.png`}
							alt="Company Logo"
							style={{ height: "40px", marginRight: "16px" }} // Adjust the height and margin as needed
						/>
						<strong>
							<h2>GymG</h2>
						</strong>
					</Button>
				</div>
				<div>
					
				
					<Button>
						<BasicMenu />
					</Button>
				</div>
			</Toolbar>
		</AppBar>
	);
}

export default NavBar;

// import React from "react";
// import { AppBar, Toolbar, Button, Link, Typography, rgb } from "@mui/material";

// const NavBar = () => {
// 	return (
// 		<AppBar position="static">
// 			<Toolbar style={{ justifyContent: "space-between" }}>
// 				{/* Logo */}
// 				<Typography variant="h6">
// 					<Button component={Link} to="/home" style={{ color: "white" }}>
// 						<img
// 							src={`${process.env.PUBLIC_URL}/gaa.png`}
// 							alt="Company Logo"
// 							style={{ height: "40px", marginRight: "16px" }} // Adjust the height and margin as needed
// 						/>
// 						GymBro
// 					</Button>
// 				</Typography>

// 				{/* Navigation Buttons */}
// 				<div>
//                 <Button color="inherit" component={Link} to="/">
// 					Home
// 				</Button>
// 				<Button color="inherit" component={Link} to="/login">
// 					Login
// 				</Button>
// 				<Button color="inherit" component={Link} to="/register">
// 					Register
// 				</Button>
// 				</div>
// 			</Toolbar>
// 		</AppBar>
// 	);
// };

// export default NavBar;
