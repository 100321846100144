import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Link } from "react-router-dom";

export default function PricingCards() {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
		  justifyContent: 'center',
          gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
          gap: 2,
		  ml: 'auto', mr: 'auto',
        }}
      >
        
        <Card
          size="lg"
          variant="solid"
          color="neutral"
          invertedColors
          sx={{  bgcolor: '#9c27b0' }}
        >
          <Chip size="sm" variant="outlined">
            MOST POPULAR
          </Chip>
          <Typography level="h2">Unlimited Plan</Typography>
          <Divider inset="none" />
          <List
            size="sm"
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              mx: 'calc(-1 * var(--ListItem-paddingX))',
            }}
          >
            <ListItem>
              <ListItemDecorator>
                <Check />
              </ListItemDecorator>
              Schedule editor
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <Check />
              </ListItemDecorator>
             Personal AI Gym assistant
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <Check />
              </ListItemDecorator>
              Access 800+ workouts with instructions
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <Check />
              </ListItemDecorator>
              Share you schedule
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <Check />
              </ListItemDecorator>
              Cancel Anytime
            </ListItem>
          </List>
          <Divider inset="none" />
          <CardActions>
            <Typography level="title-lg" sx={{ ml: 'auto', mr: 'auto' }}>
              3.99€{' '}
              <Typography fontSize="sm" textColor="text.tertiary">
                / month
              </Typography>
            </Typography>
            <Button endDecorator={<KeyboardArrowRight />} component={Link} to="/register" >Start now</Button>
          </CardActions>
        </Card>
      </Box>
    );
  }