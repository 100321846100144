import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PhoneIcon from "@material-ui/icons/Phone";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import Peer from "simple-peer";
import io from "socket.io-client";
import "./p2p.css";

const socketioUrl = process.env.REACT_APP_SOCKETIO_URL;
const socket = io.connect(socketioUrl);
const serverUrl = process.env.REACT_APP_BACK_END_URL;

function Challenge() {
  
  const [me, setMe] = useState("");
  const [stream, setStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [emailToCall, setEmailToCall] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState("");
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    console.log("useEffect called");

    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
      console.log("Stream obtained:", stream);
      setStream(stream);
      if (myVideo.current) {
        myVideo.current.srcObject = stream;
        console.log("Assigned stream to myVideo");
      }
    });

    if (!isMounted.current) {
      isMounted.current = true;

      socket.on("me", (id) => {
        console.log("Received socket ID:", id);
        setMe(id);
        const formData = {
          email: JSON.parse(localStorage.getItem("user")).email,
          signalID: id,
        };
        console.log("Sending signaling data to server:", formData);
        axios.post(serverUrl + "/update-signaling", formData)
          .then(response => {
            console.log("Signaling data updated successfully:", response.data);
          })
          .catch(error => {
            console.error("There was an error updating the signaling data:", error);
          });
      });

      socket.on("callUser", (data) => {
        console.log("Incoming call:", data);
        setReceivingCall(true);
        setCaller(data.from);
        setName(data.name);
        setCallerSignal(data.signal);
      });
    }
  }, []);

  const callUser = async () => {
    try {
      console.log("Attempting to call user with email:", emailToCall);
      const formData =  { email: emailToCall };
      const response = await axios.post(serverUrl + "/get-signal-id", formData);
      const signalID = response.data.signalID;
      console.log("Received signal ID for email:", signalID);

      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream: stream
      });

      peer.on("signal", (data) => {
        console.log("Emitting callUser with signal data:", data);
        socket.emit("callUser", {
          userToCall: signalID,
          signalData: data,
          from: me,
          name: name
        });
      });

      peer.on("stream", (stream) => {
        console.log("Receiving stream from peer:", stream);
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
        }
      });

      socket.on("callAccepted", (signal) => {
        console.log("Call accepted with signal:", signal);
        setCallAccepted(true);
        peer.signal(signal);
      });

      connectionRef.current = peer;
      console.log("Peer connection established:", peer);
    } catch (error) {
      console.error("There was an error fetching the signal ID:", error);
    }
  };

  const answerCall = () => {
    console.log("Answering call from:", caller);
    setCallAccepted(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream
    });

    peer.on("signal", (data) => {
      console.log("Emitting answerCall with signal data:", data);
      socket.emit("answerCall", { signal: data, to: caller });
    });

    peer.on("stream", (stream) => {
      console.log("Receiving stream from peer:", stream);
      if (userVideo.current) {
        userVideo.current.srcObject = stream;
      }
    });

    peer.signal(callerSignal);
    connectionRef.current = peer;
    console.log("Peer connection established for answering call:", peer);
  };

  const leaveCall = () => {
    console.log("Ending call");
    setCallEnded(true);
    if (connectionRef.current) {
      connectionRef.current.destroy();
      console.log("Destroyed peer connection");
    }
  };

  return (
    <>
      <h1 style={{ textAlign: "center", color: '#fff' }}>Gym Batto</h1>
      <div className="container">
        <div className="video-container">
          <div className="video">
            {stream && <video playsInline muted ref={myVideo} autoPlay />}
          </div>
          <div className="video2">
            {callAccepted && !callEnded ?
              <video playsInline ref={userVideo} autoPlay /> :
              null}
          </div>
        </div>
        <div className="myId">
          <TextField
            id="filled-basic"
            label="Caller name"
            variant="filled"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          <CopyToClipboard text={me} style={{ marginBottom: "2rem" }}>
            <Button variant="contained" color="primary" startIcon={<AssignmentIcon fontSize="large" />}>
              Copy ID
            </Button>
          </CopyToClipboard>

          <TextField
            id="filled-basic"
            label="Email to call"
            variant="filled"
            value={emailToCall}
            onChange={(e) => setEmailToCall(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          <div className="call-button">
            {callAccepted && !callEnded ? (
              <Button variant="contained" color="secondary" onClick={leaveCall}>
                End Call
              </Button>
            ) : (
              <IconButton color="primary" aria-label="call" onClick={callUser}>
                <PhoneIcon fontSize="large" />
              </IconButton>
            )}
            {emailToCall}
          </div>
        </div>
        <div>
          {receivingCall && !callAccepted ? (
            <div className="caller">
              <h1>{name} is calling...</h1>
              <Button variant="contained" color="primary" onClick={answerCall}>
                Answer
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Challenge;
