// src/components/DrawerControls.js
import React from 'react';
import { List, ListItem, ListItemText, IconButton, RadioGroup, FormControlLabel, Radio, FormLabel, Select, MenuItem } from '@mui/material';
import ArrowRight from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowLeft from "@mui/icons-material/ArrowCircleLeftOutlined";

const DrawerControls = ({ daysOfWeek, currentDay, previousDay, nextDay, selectedWeek, setSelectedWeek, selectedDifficulty, setSelectedDifficulty, getEquipmentTypes, selectedEquipment, setSelectedEquipment }) => {
  return (
    <List component="nav">
      <div
        style={{
          background: "#f6f9fc",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: window.innerWidth <= 420 ? "column" : "row",
        }}
        onClick={() => {}} // Add a relevant function if needed
      >
        <IconButton
          style={{ marginLeft: window.innerWidth <= 420 ? "0px" : "20%" }}
          aria-label=""
          onClick={previousDay}
        >
          <ArrowLeft />
        </IconButton>
        <ListItemText
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: window.innerWidth <= 420 ? "column" : "row",
          }}
          primary={daysOfWeek[currentDay].day}
        />
        <IconButton
          style={{ marginRight: window.innerWidth <= 420 ? "0px" : "20%" }}
          aria-label=""
          onClick={nextDay}
        >
          <ArrowRight />
        </IconButton>
      </div>
      <ListItem
        button
        style={{
          background: "#f6f9fc",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: window.innerWidth <= 768 ? "column" : "row",
        }}
      >
        <FormLabel id="demo-row-radio-buttons-group-label">Week</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedWeek}
          onChange={(event) => setSelectedWeek(event.target.value)}
        >
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
        </RadioGroup>
      </ListItem>
      <ListItem
        style={{
          background: "#f6f9fc",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: window.innerWidth <= 768 ? "column" : "row",
        }}
      >
        <div
          style={{
            height: 40,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            label="Difficulty"
            value={selectedDifficulty}
            onChange={(event) => setSelectedDifficulty(event.target.value)}
            id="difficulty-select"
          >
            <MenuItem value="Beginner">Level Beginner</MenuItem>
            <MenuItem value="Intermediate">Level Intermediate</MenuItem>
            <MenuItem value="Expert">Level Expert</MenuItem>
          </Select>
        </div>{" "}
      </ListItem>
    </List>
  );
};

export default DrawerControls;
