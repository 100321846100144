// src/components/AccordionSection.js
import React from 'react';
import { InputLabel, Accordion, AccordionSummary, IconButton, AccordionDetails, Typography, RadioGroup, FormControlLabel, Radio, FormLabel, Select, MenuItem, TextField, InputAdornment, Pagination } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ExerciseCard from './exerciseCard';

import CardContent from "@mui/material/CardContent";
import PhotoGallery from "./PhotoGallery";
import styled from "@emotion/styled";
import Chat from "./chatComponent";
import Form from "./formComponent";
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";


const LockedOverlay = styled.div`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(255, 255, 255, 0.8); // semi-transparent white
z-index: 10; // ensure it overlays the content
`;
const AccordionSection = ({ title, children }) => (
  <Accordion style={{ margin: '0 auto', maxWidth: "90%" }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
);

export const SearchAccordion = ({ searchQuery, setSearchQuery, selectedDifficulty, setSelectedDifficulty, selectedEquipment, setSelectedEquipment, getEquipmentTypes, currentExercises, addExercise, isDisabled, pageNumbers, handlePageChange, children }) => (
  <AccordionSection title="Search">
    <div style={{ height: 40, cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "90%" }}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={selectedDifficulty}
        onChange={(event) => setSelectedDifficulty(event.target.value)}
      >
        <FormLabel>Beginner</FormLabel>
        <FormControlLabel value="Beginner" control={<Radio />} />
        <FormLabel>Intermediate</FormLabel>
        <FormControlLabel value="Intermediate" control={<Radio />} />
        <FormLabel>Expert</FormLabel>
        <FormControlLabel value="Expert" control={<Radio />} />
      </RadioGroup>
    </div>
    <Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Select
          label="Equipment"
          value={selectedEquipment}
          onChange={(event) => {
            setSelectedEquipment(event.target.value);
            console.log(event.target.value);
          }}
          id="equipment-select"
        >
          {getEquipmentTypes().map((equipment) => (
            <MenuItem key={equipment} value={equipment}>
              {equipment}
            </MenuItem>
          ))}
        </Select>
        <TextField
          variant="outlined"
          label="Search"
          fullWidth
          value={searchQuery}
          sx={{ width: "50%" }}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
        <Pagination xs={{ width: "100%" }} count={pageNumbers.length} variant="outlined" onChange={handlePageChange} />
      </div>
      <div>
        <ExerciseCard exercises={currentExercises} expand={() => {}} applyToDay={addExercise} isDisabled={isDisabled} />
      </div>
    </Typography>
    {children}
  </AccordionSection>
);

export const ScheduleAccordion = ({selectedPersona, setIsScheduleRequired, handlePersonaChange, isScheduleRequired, isDisabled, subscribeLink, daysOfWeek, currentDay, selectedWeek, getRepsnSetsByDifficulty, selectedDayWorkouts, moveWorkoutUp, moveWorkoutDown, removeExercise, sendQuestion, isLoading, messages, clearMessages, children }) => (
  <AccordionSection title="Schedule">
    {isDisabled && (
      <LockedOverlay>
        <LockIcon color="primary" style={{ fontSize: 40 }} />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            // Navigate to the subscription page (modify the path as needed)
            window.location.href = subscribeLink;
          }}
        >
          Subscribe
        </Button>
      </LockedOverlay>
    )} 
    <Typography>
      <Form onSubmit={sendQuestion} isLoading={isLoading} selectedPersona={selectedPersona} handlePersonaChange={handlePersonaChange} setIsScheduleRequired={setIsScheduleRequired} isScheduleRequired={isScheduleRequired} />
      <Chat messages={messages} clearMessages={clearMessages} />
    </Typography>
    <Typography>
      <div>
        <h3>{daysOfWeek[currentDay].day} - Week {selectedWeek}</h3>  {children}
        {selectedDayWorkouts.workouts.length > 0 && <h4> Try to get atleast {getRepsnSetsByDifficulty()}</h4>}
        {selectedDayWorkouts && selectedDayWorkouts.workouts.map((exercise, index) => (
          <div class="exercise-container" key={index}>
            <div class="icon-buttons">
              <IconButton onClick={() => moveWorkoutUp(daysOfWeek, daysOfWeek[currentDay].day, selectedWeek, exercise.id)}>
                <ExpandLessIcon />
              </IconButton>
              <IconButton onClick={() => moveWorkoutDown(daysOfWeek, daysOfWeek[currentDay].day, selectedWeek, exercise.id)}>
                <ExpandMoreIcon />
              </IconButton>
            </div>
            <div class="content-container">
              <PhotoGallery photos={exercise.images} />
              <CardContent style={{ maxWidth: "70%" }}>
                <Checkbox label="Mark as done" checked={exercise.markedAsComplete} />
                <Typography variant="h5" component="div">
                  The {exercise.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {exercise.instructions}
                </Typography>
                <Button onClick={() => removeExercise(exercise)}>Remove</Button>
              </CardContent>
            </div>
          </div>
        ))}
        {selectedDayWorkouts && selectedDayWorkouts.workouts.length === 0 && "Nothing scheduled for this day"}
      </div>
    </Typography>
    {isDisabled && (
      <LockedOverlay>
        <LockIcon color="primary" style={{ fontSize: 40 }} />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            // Navigate to the subscription page (modify the path as needed)
            window.location.href = subscribeLink;
          }}
        >
          Subscribe
        </Button>
      </LockedOverlay>
    )}
   
  </AccordionSection>
);
