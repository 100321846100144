// import React from "react";
// import { Container, Grid, Typography } from "@mui/material";

// const Footer = () => {
// 	return (
// 		<footer
// 			aria-label="Footer Navigation"
// 			className="wrapper bg-white relative">
// 			<Container>
// 				<Grid container spacing={4}>
// 					<Grid item xs={12} lg={4}>
// 						<div className="flex justify-start items-center space-x-2">
// 							<Typography
// 								variant="h6"
// 								component="span"
// 								fontWeight="bold"
// 								fontSize="14px">
// 								GymBro
// 							</Typography>
// 						</div>
// 					</Grid>
// 					<Grid item xs={12} lg={8}>
// 						{/* Your navigation content goes here */}
// 					</Grid>
// 				</Grid>
// 			</Container>
// 			<Container>
// 				<div className="flex justify-center lg:justify-end items-center w-full lg:w-auto">
// 					<img
// 						alt="MasterCard Logo"
// 						src={`${process.env.PUBLIC_URL}/mastercard.svg`}
// 						style={{ maxWidth: "120px" }}
// 					/>
// 					<img
// 						alt="Visa Logo"
// 						src={`${process.env.PUBLIC_URL}/visa.svg`}
// 						style={{ maxWidth: "120px" }}
// 					/>
// 				</div>
// 			</Container>
// 			<Container>{/* Legal and copyright */}</Container>
// 		</footer>
// 	);
// };

// export default Footer;

// src/componetns/Footer.tsx

import React, { FC, ReactElement } from "react";
import { Avatar, Box, Container, Grid, Typography } from "@mui/material";

export const Footer = () => {
	return (
		<Box
			sx={{
				width: "100%",
				height: "auto",
				backgroundColor: "secondary.main",
				paddingTop: "1rem",
				paddingBottom: "1rem",
			}}>
			<Container maxWidth="lg">
				<Grid container direction="column" alignItems="center">
					<Grid item xs={12}>
						<Typography color="black" variant="h5">
							<div style={{ display: "flex", alignItems: "center" }}>
								<Avatar
									alt="Visa Logo"
									src={`${process.env.PUBLIC_URL}/gaa.png`}
									style={{ maxWidth: "45px", marginRight: "10px" }}
								/>
								<p style={{ color: "white" }}>GymG</p>
							</div>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color="white" variant="subtitle1">
							© GymG &nbsp;
							{`${new Date().getFullYear()} Your personal gym assistant and workout schedule manager.`}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
