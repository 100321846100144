import React from "react";
import { Card, CardContent, Typography, Avatar } from "@mui/material";

function FeatureCard({ title, description, imageUrl }) {
	return (
		<Card
			style={{
				maxWidth: "300px",
				maxHeight: '400px',
				minHeight: '400px',
				margin: "10px",
				color: "#eeeeee",
				backgroundColor: "#66C3D5",
			}}>
			<CardContent
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				{/* Circle portrait */}
				<Avatar
					src={imageUrl}
					alt={title}
					style={{
						width: "200px",
						height: "200px",
						marginBottom: "15px",
						color: "#eeeeee",
					}}
				/>

				<Typography variant="h6" component="div">
					{title}
				</Typography>
				<Typography variant="body2" style={{ color: "#eeeeee" }} component="p">
					{description}
				</Typography>
			</CardContent>
		</Card>
	);
}

export default FeatureCard;
