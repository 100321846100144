import React, { useState, useRef, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, TextField, Button, Typography, Snackbar, Alert, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VideoIcon from "@mui/icons-material/Videocam";
import VideoOffIcon from "@mui/icons-material/VideocamOff";
import { useSpring, animated } from "@react-spring/web";
import { useSwipeable } from "react-swipeable";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import PhotoGallery from "./PhotoGallery";
import P2p from './p2pChat';

const ChatAccordion = ({ workoutMode, setWorkoutMode, currentDayWorkouts, completedExercises, handleCompleteExercise, handleFinishWorkout, serverUrl }) => {
	const [email, setEmail] = useState(JSON.parse(localStorage.getItem("user")).email);
	const [peerEmail, setPeerEmail] = useState("");
	const [message, setMessage] = useState("");
	const [chat, setChat] = useState([]);
	const [status, setStatus] = useState("");
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [peerConnection, setPeerConnection] = useState(null);
	const [dataChannelOpen, setDataChannelOpen] = useState(false);
	const [videoEnabled, setVideoEnabled] = useState(false);
	const [currentWorkoutIndex, setCurrentWorkoutIndex] = useState(0);
	const [workoutVisible, setWorkoutVisible] = useState(true);


	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	// UI rendering for chat and workout
	return (
		<Accordion style={{ margin: "0 auto", maxWidth: "90%" }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>Chat and Workout</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<div>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setWorkoutMode(!workoutMode)}
					>
						{workoutMode ? "Switch to Workout Mode" : "Switch to Challenge Mode"}
					</Button>
					{workoutMode ? (
						<div>
							<div style={{ position: "relative", width: "100%", height: "auto" }}>
							<P2p></P2p>
								{currentDayWorkouts.length > 0 && (
									<div
										// {...swipeHandlers}
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
											background: "rgba(0, 0, 0, 0.5)",
											color: "white",
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
											padding: "20px",
											boxSizing: "border-box",
											zIndex: 5,
										}}
									>
										<IconButton
											// onClick={showPrevWorkout}
											style={{
												position: "absolute",
												left: 10,
												top: "50%",
												transform: "translateY(-50%)",
												zIndex: 10,
											}}
										>
											{workoutVisible && <ArrowBackIcon style={{ color: "white" }} />}
										</IconButton>
										<IconButton
											// onClick={toggleWorkoutVisibility}
											style={{ top: 10, right: 10, zIndex: 15 }}
										>
											{workoutVisible ? <VisibilityOffIcon style={{ color: "white" }} /> : <VisibilityIcon style={{ color: "white" }} />}
										</IconButton>
										{workoutVisible && (
											<>
												<Typography
													variant="h6"
													style={{ marginBottom: "10px" }}
												>
													Workout {currentWorkoutIndex + 1} / {currentDayWorkouts.length}
												</Typography>
												<ExerciseCard
													exercise={currentDayWorkouts[currentWorkoutIndex]}
													isCompleted={completedExercises.includes(currentDayWorkouts[currentWorkoutIndex]?.id)}
													onComplete={handleCompleteExercise}
												/>
												<IconButton
													// onClick={showNextWorkout}
													style={{
														position: "absolute",
														right: 10,
														top: "50%",
														transform: "translateY(-50%)",
														zIndex: 10,
													}}
												>
													<ArrowForwardIcon style={{ color: "white" }} />
												</IconButton>
												<Typography
													variant="body1"
													style={{ marginTop: "10px" }}
												>
													Swipe left or right to navigate workouts
												</Typography>
												{completedExercises.length === currentDayWorkouts.length && (
													<animated.div >
														<Button
															onClick={handleFinishWorkout}
															variant="contained"
															color="secondary"
														>
															Finish Workout
														</Button>
													</animated.div>
												)}
											</>
										)}
									</div>
								)}
							</div>
						</div>
					) : (
						<div>
							<TextField
								label="Your Email"
								variant="outlined"
								fullWidth
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								style={{ marginBottom: "10px", width: "300px" }}
							/>
							<br />
							<TextField
								label="Peer Email"
								variant="outlined"
								fullWidth
								value={peerEmail}
								onChange={(e) => setPeerEmail(e.target.value)}
								style={{ marginBottom: "10px", width: "300px" }}
							/>
							<div style={{ marginBottom: "10px" }}>
							
							</div>
							<div style={{ marginBottom: "10px" }}>
								{chat.map((msg, index) => (
									<Typography key={index}>{msg}</Typography>
								))}
							</div>
							<Snackbar
								open={snackbarOpen}
								autoHideDuration={6000}
								onClose={handleSnackbarClose}
							>
								<Alert
									onClose={handleSnackbarClose}
									severity="info"
									sx={{ width: "100%" }}
								>
									{status}
								</Alert>
							</Snackbar>
						</div>
					)}
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

// Exercise card component to display exercise details and completion status
const ExerciseCard = ({ exercise, isCompleted, onComplete }) => {
	const fade = useSpring({
		opacity: isCompleted ? 1 : 0,
		transform: isCompleted ? "scale(1)" : "scale(0.9)",
	});

	const handleCompleteClick = () => {
		if (!isCompleted) {
			onComplete(exercise.id);
		}
	};

	return (
		<div
			style={{
				maxWidth: "320px",
				position: "relative",
				marginBottom: "10px",
				border: "1px solid #ddd",
				padding: "10px",
				borderRadius: "5px",
				backgroundColor: "#fff",
				zIndex: 5,
			}}
		>
			<h3>{exercise.name}</h3>
			<div>
				<PhotoGallery photos={exercise.images} />
			</div>
			<p>{exercise.instructions.join(" ")}</p>
			{!isCompleted && (
				<Button
					onClick={handleCompleteClick}
					variant="contained"
					color="primary"
					style={{ marginTop: "10px" }}
				>
					Complete
				</Button>
			)}
			{isCompleted && (
				<animated.div style={fade}>
					<Typography style={{ color: "green" }}>Completed!</Typography>
				</animated.div>
			)}
			{isCompleted && (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						background: "rgba(255, 255, 255, 0.8)",
						borderRadius: "5px",
						zIndex: 6,
					}}
				>
					<CheckCircleOutlineIcon style={{ fontSize: "100px", color: "green" }} />
				</div>
			)}
		</div>
	);
};

export default ChatAccordion;
