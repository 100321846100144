import React from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./components/home";
import Login from "./components/login";
import Register from "./components/register";
import NavBar from "./components/navbar";
import AppSelection from "./components/appSelection";
import Gymbro from "./components/gymbro/gymbro";
import ProfilePage from "./components/profilePage.js";
import ProtectedRoute from "./components/Auth/privateRoute";
import foohfo from "./components/404";
import Footer from "./components/Footer";
import Payment from "./components/Payment/payment";
// import Completion from "./components/Completion";
import axios from "axios";
import StartWorkout from "./components/startWorkout.js";
import P2PChat from "./components/gymbro/components/p2pChat.js";

function App() {
	const checkAuth = async () => {
		const token = localStorage.getItem("token");
		
		const response = await axios({
			method: "get",
			url: "http://localhost:4000/check-token",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					console.log("Token is valid:", response.data);
				} else {
					console.error("Token verification failed:", response.data);
				}
			})
			.catch((error) => {
				
				console.error("An error occurred:", error);
			});
	};
	//checkAuth();

	return (
		<div
			style={{
				backgroundImage: `url('${process.env.PUBLIC_URL}/background2.png')`,
				backgroundSize: "cover", // Ensures the background image covers the entire div
				objectFit: true,
			}}>
			<Router>
				<NavBar />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/login" element={<Login />} />
					<Route path="/register" element={<Register />} />
					<Route path="/p2p" element={<P2PChat />} />
					<Route
						path="/AppSelection"
						element={
							<ProtectedRoute>
								<AppSelection />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Gymbro"
						element={
							<ProtectedRoute>
								<Gymbro />
							</ProtectedRoute>
						}
					/>
					<Route path="/payment" element={<Payment />} />
					<Route path="/profilePage" element={<ProfilePage />} />
					<Route path="/startWorkout" element={<StartWorkout />} />
					<Route path="*" element={<foohfo />} />
				</Routes>
			</Router>
			<Footer />
		</div>
	);
}

export default App;
