import React from "react";
import { Container, Typography, Button, Grid } from "@mui/material";

import { Link } from "react-router-dom";
import FeatureCard from "../components/feature";
import Pricing from "../components/Payment/pricing";

function Home() {
	return (
		<Container maxWidth="lg">
			{/* Hero Section */}
			<Grid
				sx={{ backgroundSize: "cover", // Ensures the background image covers the entire div

				objectFit: true,backgroundImage: `url('${process.env.PUBLIC_URL}/home 6.jpg')` }}
				container
				spacing={3}
				style={{ marginTop: "50px", marginBottom: "50px", color: "white" }}>
				<Grid item md={6} xs={12}>
					<Typography variant="h2" gutterBottom>
						Unleash Your Potential!
					</Typography>
					<Typography variant="h6" gutterBottom>
						Dive into a world of premium features designed to elevate your
						success. Join us today and redefine what's possible.
					</Typography>
					<Button
						variant="contained"
						color="primary"
						size="large"
						component={Link}
						to="/subcribe">
						Get Started
					</Button>
				</Grid>
				<Grid item md={6} xs={12}>
					{/* You can place an image or some graphic here */}
				</Grid>
			</Grid>

			{/* Features/Benefits Section */}
			<Grid container spacing={4} style={{ marginTop: "20px" }}>
				{/* Feature 1: Create your own workout plan */}
				<Grid item xs={12} sm={4}>
					<FeatureCard
						title="Create Your Own Workout Plan"
						description="Design a workout plan that fits your needs. Personalize exercises, durations, and rest intervals."
						imageUrl={`${process.env.PUBLIC_URL}/homepic1 (3).jpg`}
					/>
				</Grid>

				{/* Feature 2: AI gym assistant */}
				<Grid item xs={12} sm={4}>
					<FeatureCard
						title="AI Gym Assistant"
						description="Ask your AI gym assistant any fitness-related questions. Get instant guidance, form corrections, and nutritional advice."
						imageUrl={`${process.env.PUBLIC_URL}/homepic1 (2).jpg`}
					/>
				</Grid>

				{/* Feature 3: Search workouts */}
				<Grid item xs={12} sm={4}>
					<FeatureCard
						title="Search Workouts"
						description="Dive into our extensive workout database and find exercises suitable for all levels and goals."
						imageUrl={`${process.env.PUBLIC_URL}/homepic1 (1).jpg`}
					/>
				</Grid>
			</Grid>

			{/* Testimonials Section */}
			{/* ... */}
		
			{/* CTA */}
			<Grid
				container
				spacing={3}
				style={{ marginTop: "50px", marginBottom: "50px" }}>
				<Grid item xs={12} style={{ textAlign: "center" }}>
					<Typography variant="h5" gutterBottom style={{ color: "white" }}>
						Ready to start your journey?
					</Typography>	<Pricing></Pricing>
					{/* <Button variant="contained" color="secondary" size="large">
						Subscribe Now
					</Button> */}
				</Grid>
			</Grid>
		</Container>
	);
}

export default Home;
