import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import exercises from "./exercisesjson/exercises.json";
import DrawerControls from "./components/DrawerControls";
import { SearchAccordion, ScheduleAccordion } from "./components/AccordionSection";
import ChatAccordion from "./components/RTSChat";
import Paper from "@mui/material/Paper";
import { Drawer, Fab, Box, Button, Select, MenuItem, FormControl, InputLabel, TextField, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles"; //'http://localhost:4000';

const serverUrl = process.env.REACT_APP_BACK_END_URL;
const GymBro = () => {
	const theme = useTheme();
	const [messages, setMessages] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [selectedWeek, setSelectedWeek] = useState("1"); // Default to "1"
	const [selectedDifficulty, setSelectedDifficulty] = useState("Beginner");
	const [selectedEquipment, setSelectedEquipment] = useState("body only");
	const [filteredByWeekExercises, setFilteredByWeekExercises] = useState(null);
	const [isDocked, setIsDocked] = useState(false);
	const [currentDay, setCurrentDay] = useState(0);
	const [open, setOpen] = useState(true);
	const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const [openAccordion, setOpenAccordion] = useState(true);
	const [persona, setPersona] = useState(localStorage.getItem("persona") ? localStorage.getItem("persona") : "Arnold Schwarzenegger");
	const [isScheduleRequired, setIsScheduleRequired] = useState(false);
	const [recipientEmail, setRecipientEmail] = useState(""); // New state for recipient's email
	let subscribeLink = "https://buy.stripe.com/test_bIY9BU9NHcIa0a43cc" + "?prefilled_email=" + JSON.parse(localStorage.getItem("user")).email;

	const [workoutMode, setWorkoutMode] = useState(false);
	const [completedExercises, setCompletedExercises] = useState([]);

	const handleCompleteExercise = (exerciseId) => {
		setCompletedExercises((prev) => [...prev, exerciseId]);
	};

	const handleFinishWorkout = async () => {
		try {
			const formData = {
				username: JSON.parse(localStorage.getItem("user")).email,
				completedExercises: completedExercises,
			};

			await axios.post(serverUrl + "/completed-exercises", formData);
			// Reset completed exercises or update UI as needed
			setCompletedExercises([]);
		} catch (error) {
			console.error("Failed to save completed workouts:", error);
		}
	};

	const filterExerciseDB = () => {
		return encodeExercises(
			exercises.map(({ name, level, category, equipment }) => ({
				name,
				level,
				category,
				equipment,
			}))
		);
	};

	const getExerciselist = (selectedEquipment) => {
		console.log("filtering by ;> ", selectedEquipment);
		let ex = exercises.map((exercise) => {
			if (exercise.equipment == selectedEquipment) return exercise.name;
		});
		console.log("ex=> ", ex);
		return ex;
	};

	const getEquipmentTypes = () => {
		const uniqueEquipmentTypes = Array.from(new Set(exercises.map((exercise) => exercise.equipment)));
		return uniqueEquipmentTypes.filter((equipment) => equipment !== null);
	};

	const clearMessages = () => {
		setMessages([]);
		localStorage.setItem("messages", []);
	};

	const loadFromLocalStorage = () => {
		try {
			const storedData = localStorage.getItem("daysOfWeekData");

			if (storedData) {
				return JSON.parse(storedData);
			}
		} catch (error) {
			console.error("Failed to load from local storage:", error);
		}
		return null;
	};

	const [isDisabled, setIsDisabled] = useState(true);

	const [initialData, setInitialData] = useState([
		{ day: "Monday", week: 1, workouts: [] },
		{ day: "Tuesday", week: 1, workouts: [] },
		{ day: "Wednesday", week: 1, workouts: [] },
		{ day: "Thursday", week: 1, workouts: [] },
		{ day: "Friday", week: 1, workouts: [] },
		{ day: "Saturday", week: 1, workouts: [] },
		{ day: "Sunday", week: 1, workouts: [] },
		{ day: "Monday", week: 2, workouts: [] },
		{ day: "Tuesday", week: 2, workouts: [] },
		{ day: "Wednesday", week: 2, workouts: [] },
		{ day: "Thursday", week: 2, workouts: [] },
		{ day: "Friday", week: 2, workouts: [] },
		{ day: "Saturday", week: 2, workouts: [] },
		{ day: "Sunday", week: 2, workouts: [] },
		{ day: "Monday", week: 3, workouts: [] },
		{ day: "Tuesday", week: 3, workouts: [] },
		{ day: "Wednesday", week: 3, workouts: [] },
		{ day: "Thursday", week: 3, workouts: [] },
		{ day: "Friday", week: 3, workouts: [] },
		{ day: "Saturday", week: 3, workouts: [] },
		{ day: "Sunday", week: 3, workouts: [] },
		{ day: "Monday", week: 4, workouts: [] },
		{ day: "Tuesday", week: 4, workouts: [] },
		{ day: "Wednesday", week: 4, workouts: [] },
		{ day: "Thursday", week: 4, workouts: [] },
		{ day: "Friday", week: 4, workouts: [] },
		{ day: "Saturday", week: 4, workouts: [] },
		{ day: "Sunday", week: 4, workouts: [] },
	]);

	const [daysOfWeek, setDaysOfWeek] = useState(loadFromLocalStorage() || initialData);
	const divRef = useRef(null);

	const [workoutName, setWorkoutName] = useState("");
	const [savedWorkouts, setSavedWorkouts] = useState(JSON.parse(localStorage.getItem("savedWorkouts")) || {});
	const [selectedWorkout, setSelectedWorkout] = useState("");
	const [sharedWorkouts, setSharedWorkouts] = useState([]);
	const [selectedSharedWorkout, setSelectedSharedWorkout] = useState("");

	useEffect(() => {
		if (isDisabled) {
			checkIfIsActive();
		}
		const localMessages = localStorage.getItem("messages");
		if (localMessages != "" && messages == "") {
			setMessages(JSON.parse(localMessages));
		}

		generatePhrases(actions, types, descriptors);
		fetchSharedWorkouts(); // Fetch shared workouts on load
	}, []);

	const handlePersonaChange = (event) => {
		localStorage.setItem("persona", event.target.value);
		setPersona(event.target.value);
	};

	const checkIfIsActive = async () => {
		try {
			const formData = {
				email: JSON.parse(localStorage.getItem("user")).email,
			};
			const response = await axios.post(serverUrl + "/user", formData);
			if (response.data.userDetails.status == "active") {
				setIsDisabled(false);
			}
			localStorage.setItem("user", JSON.stringify(response.data.userDetails));
		} catch (error) {
			console.log("error:", error);
		}
	};

	const saveToLocal = async () => {
		try {
			localStorage.setItem("daysOfWeekData", JSON.stringify(daysOfWeek));
		} catch (error) {
			console.error("Failed to save to local storage:", error);
		}
	};

	const nextDay = () => {
		let newDay = currentDay == 6 ? 0 : currentDay + 1;
		setCurrentDay(newDay);
	};

	const previousDay = () => {
		let newDay = currentDay == 0 ? 6 : currentDay - 1;
		setCurrentDay(newDay);
	};

	function getExerciseDetails(exerciseName, exercises) {
		return exercises.find((exercise) => exercise.name === exerciseName);
	}

	function updateWorkoutPlanWithExercises(workoutPlan, newWorkouts, exercises) {
		if (newWorkouts) {
			console.log("workoutPlan:", JSON.stringify(workoutPlan, null, 2));

			console.log("newWorkouts:", JSON.stringify(newWorkouts, null, 2));
			console.log("exercises:", JSON.stringify(exercises, null, 2));

			const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

			// Initialize the workout plan with 4 weeks and 7 days structure if not already
			for (let weekNum = 1; weekNum <= 4; weekNum++) {
				for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
					const dayName = daysOfWeek[dayIndex];
					if (!workoutPlan.some((d) => d.week === weekNum && d.day === dayName)) {
						workoutPlan.push({
							day: dayName,
							week: weekNum,
							workouts: [],
						});
					}
				}
			}

			for (const [weekKey, days] of Object.entries(newWorkouts)) {
				const weekNum = parseInt(weekKey.replace("week", ""), 10);

				for (const [dayKey, exercisesList] of Object.entries(days)) {
					console.log(`Processing week ${weekNum}, day ${dayKey}:`, exercisesList);
					const dayIndex = parseInt(dayKey.replace("day", ""), 10) - 1;
					const dayName = daysOfWeek[dayIndex];

					let matchingDay = workoutPlan.find((d) => d.week === weekNum && d.day === dayName);

					if (!matchingDay) {
						// If the day does not exist, create a new day entry
						matchingDay = {
							day: dayName,
							week: weekNum,
							workouts: [],
						};
						workoutPlan.push(matchingDay);
					}

					// Ensure exercisesList is an array
					const exercisesArray = Array.isArray(exercisesList) ? exercisesList : [exercisesList];
					console.log(`Exercises array for ${dayName}, week ${weekNum}:`, exercisesArray);

					matchingDay.workouts = exercisesArray
						.map((exerciseName) => {
							const exerciseDetails = getExerciseDetails(exerciseName, exercises);
							if (exerciseDetails) {
								return {
									...exerciseDetails,
									isSelected: false,
									markedAsDone: false,
									expanded: false,
								};
							}
							// Return null if exercise not found in the list
							return null;
						})
						.filter((exercise) => exercise !== null); // Filter out null values
				}
			}

			// Ensure all days are present and initialize workouts if missing
			for (let weekNum = 1; weekNum <= 4; weekNum++) {
				for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
					const dayName = daysOfWeek[dayIndex];
					let dayEntry = workoutPlan.find((d) => d.week === weekNum && d.day === dayName);
					if (!dayEntry) {
						dayEntry = {
							day: dayName,
							week: weekNum,
							workouts: [],
						};
						workoutPlan.push(dayEntry);
					}
				}
			}

			// Ensure the workout plan is sorted by week and day
			workoutPlan.sort((a, b) => a.week - b.week || daysOfWeek.indexOf(a.day) - daysOfWeek.indexOf(b.day));
		}
		return workoutPlan;
	}

	const actions = ["workout", "exercise", "fitness", "training", "gym"];
	const types = ["plan", "routine", "schedule", "program", "plan", "regimen", "guide"];
	const descriptors = ["custom", "personalized", "tailored", "beginner", "advanced", "home", "daily", "weekly", "monthly"];
	const additionalKeywords = ["advice", "tips"];
	const [phrasesGenerated, setPhrasesGenerated] = useState([]);
	// Combine all keywords into a single array
	const keywords = [...actions, ...types, ...descriptors, ...additionalKeywords];

	// Step 2: Generate phrases
	function generatePhrases(actions, types, descriptors) {
		let phrases = [];

		actions.forEach((action) => {
			types.forEach((type) => {
				phrases.push(`${action} ${type}`);
				descriptors.forEach((descriptor) => {
					phrases.push(`${descriptor} ${action} ${type}`);
					phrases.push(`${action} ${descriptor} ${type}`);
				});
			});
		});

		// Add single keywords to the phrases list
		phrases = phrases.concat(actions, types, descriptors);

		// Remove duplicates
		phrases = [...new Set(phrases)];
		setPhrasesGenerated(phrases);
		return phrases;
	}

	// Step 3: Detect keywords in input string
	function detectKeywords(inputString, phrases) {
		console.log("phrases::", phrases);
		const pattern = new RegExp(`\\b(${phrases.join("|")})\\b`, "gi");
		const matches = inputString.match(pattern);

		let result;
		if (matches && matches.length > 0) {
			result = { found: true };
		} else {
			result = { found: false };
		}

		return result;
	}

	function encodeExercises(exercises) {
		return exercises
			.map((exercise) => {
				// Shorten equipment type if it's 'body only'
				const equipment = exercise.equipment === "body only" ? "bo" : exercise.equipment;

				// Shorten level
				const levelMap = {
					Beginner: "b",
					Intermediate: "i",
					Expert: "e",
				};
				const level = levelMap[exercise.level] || exercise.level;

				const categoryMap = {
					strength: "str",
					stretching: "strt",
					plyometrics: "plyo",
					strongman: "strong",
					powerlifting: "power",
					cardio: "cardio",
					"olympic weightlifting": "olympic",
				};
				// Shorten category
				const category = categoryMap[exercise.category] || exercise.category;
				return `${exercise.name},${level},${category},${equipment}`;
			})
			.join("','");
	}

	const sendQuestion = async (type, value) => {
		try {
			setIsLoading(true); // Set loading to true at the start of the API call

			let response = null;
			let filteredDB = isScheduleRequired ? filterExerciseDB(selectedEquipment, selectedDifficulty) : "";

			if (type === "text") {
				response = await axios.post(serverUrl + "/summarize-text", {
					text: value,
					exerciseList: filteredDB,
					persona: persona,
				});
			}

			if (type === "audio") {
				// Handle audio case
			}
			if (type === "url") {
				response = await axios.post("http://220.0.89.127:4000/summarize-url", {
					url: value,
				});
			}

			let messageList = messages ? messages : [];

			const result = await extractJsonFromString(response.data.summary);

			let newWorkoutplan = updateWorkoutPlanWithExercises(daysOfWeek, result.jsonObject, exercises);

			setDaysOfWeek(newWorkoutplan);
			localStorage.setItem("messages", JSON.stringify([{ role: "user", content: value }, { role: "assistant", content: result.cleanedString }, ...messageList]));

			setMessages([{ role: "user", content: value }, { role: "assistant", content: result.cleanedString }, ...messageList]);
		} catch (error) {
			console.error("Error getting summary", error);
		} finally {
			setIsLoading(false); // Set loading to false once the API call is finished
		}
	};

	function extractJsonFromString(str) {
		// Replace <br /> tags with newlines to convert the string into a valid JSON format
		const cleanedStr = str.replace(/<br\s*\/?>/gi, "\n");

		// Regular expression to match the JSON object between %%
		const jsonRegex = /%%\s*({[\s\S]+})\s*%%/;
		const match = cleanedStr.match(jsonRegex);

		if (match) {
			const jsonString = match[1]; // Extract the JSON string
			try {
				const jsonObject = JSON.parse(jsonString);

				// Remove the JSON object from the original string and replace <br /> tags
				let cleanedString = cleanedStr
					.replace(jsonRegex, "")
					.replace(/<br\s*\/?>/gi, "")
					.trim();

				// Remove any remaining % signs from the original string
				cleanedString = cleanedString.replace(/%%/g, "").trim();

				return {
					jsonObject,
					cleanedString,
				};
			} catch (e) {
				console.error("Error parsing JSON:", e);
				return { error: "Error parsing JSON", cleanedString: str };
			}
		} else {
			console.error("No JSON object found.");
			return { error: "No JSON object found", cleanedString: str };
		}
	}

	const [filteredExercises, setFilteredExercises] = useState(exercises);

	useEffect(() => {
		const filtered = exercises.filter((exercise) => {
			const { name, force, level, mechanic, equipment, instructions, category, primaryMuscles, secondaryMuscles } = exercise;
			if (level !== selectedDifficulty) return false;

			// Define an array of properties to search in
			const propertiesToSearch = [name, force, mechanic, equipment, category, ...instructions, ...primaryMuscles, ...secondaryMuscles];

			// Filter properties with values and check if they include the search query
			const matchingProperties = propertiesToSearch.filter((property) => typeof property === "string" && property.toLowerCase().includes(searchQuery.toLowerCase()) && equipment == selectedEquipment);

			// Include exercise in results if at least one property matches
			return matchingProperties.length > 0;
		});

		setFilteredExercises(filtered);
	}, [exercises, searchQuery, selectedDifficulty, selectedEquipment]);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentExercises = filteredExercises.slice(indexOfFirstItem, indexOfLastItem);

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(filteredExercises.length / itemsPerPage); i++) {
		pageNumbers.push(i);
	}

	const handleDrawerToggle = (state) => {
		setOpen(state);
	};

	const getRepsnSetsByDifficulty = () => {
		switch (selectedDifficulty) {
			case "Beginner":
				return "2-3 sets and around 10-12 reps per set";
			case "Intermediate":
				return "3-4 sets and around 8-10 reps per set";
			case "Expert":
				return "4-5 sets and around 6-8 reps per set";
			default:
				return "";
		}
	};

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
	}));

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	const addExercise = (newExercise) => {
		const day = currentDay;
		const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
		const week = selectedWeek;
		const position = 0;
		console.log({ day, week, position });
		let updatedDaysOfWeek = [...daysOfWeek];
		const dayObject = updatedDaysOfWeek.find((item) => item.day == days[day] && item.week == week);

		if (!dayObject) return;

		if (typeof position === "number" && position >= 0 && position < dayObject.workouts.length) {
			dayObject.workouts.splice(position, 0, newExercise);
		} else {
			dayObject.workouts.push(newExercise);
		}

		setDaysOfWeek(updatedDaysOfWeek);
		saveToLocal();
	};

	const removeExercise = (exerciseToRemove) => {
		const day = currentDay;
		const week = selectedWeek;
		const updatedDaysOfWeek = [...daysOfWeek];
		const dayObject = updatedDaysOfWeek.find((item) => item.day == days[day] && item.week == week);

		if (!dayObject) return;

		const exerciseIndex = dayObject.workouts.findIndex((exercise) => exercise.id == exerciseToRemove.id);

		if (exerciseIndex !== -1) {
			dayObject.workouts.splice(exerciseIndex, 1);
		}

		setDaysOfWeek(updatedDaysOfWeek);
		saveToLocal();
	};

	const deleteWorkout = () => {
		if (selectedWorkout) {
			const updatedSavedWorkouts = { ...savedWorkouts };
			delete updatedSavedWorkouts[selectedWorkout];
			setSavedWorkouts(updatedSavedWorkouts);
			localStorage.setItem("savedWorkouts", JSON.stringify(updatedSavedWorkouts));
			setSelectedWorkout("");
			alert("Workout plan deleted!");
		} else {
			alert("Please select a workout plan to delete.");
		}
	};
	const selectedDayWorkouts = daysOfWeek.find((item) => item.day == days[currentDay] && item.week == selectedWeek) || { workouts: [] };

	const [collapsed, setCollapsed] = useState(true);

	const handleDrawerOpen = () => {
		setOpen(!open);
		setCollapsed(!collapsed);
	};

	const handleDrawerClose = () => {
		setOpen(false);
		setCollapsed(true);
	};

	const handleTabClick = () => {
		setCollapsed(!collapsed);
	};

	const moveWorkoutUp = (schedule, day, week, workoutId) => {
		console.log({ schedule, day, week, workoutId });
		const dayIndex = schedule.findIndex((d) => d.day == day && d.week == week);

		if (dayIndex === -1) {
			console.error("Specified day and week not found.");
			return schedule;
		}

		const workouts = schedule[dayIndex].workouts;
		const workoutIndex = workouts.findIndex((workout) => workout.id === workoutId);

		if (workoutIndex < 0) {
			console.error("Specified workout not found.");
			return schedule;
		}

		if (workoutIndex === 0) {
			console.log("The workout is already at the top.");
			return schedule;
		}

		const temp = workouts[workoutIndex - 1];
		workouts[workoutIndex - 1] = workouts[workoutIndex];
		workouts[workoutIndex] = temp;

		const newSchedule = [...schedule];
		newSchedule[dayIndex].workouts = workouts;
		setDaysOfWeek(newSchedule);

		saveToLocal();
		return newSchedule;
	};

	const moveWorkoutDown = (schedule, day, week, workoutId) => {
		console.log({ schedule, day, week, workoutId });
		const dayIndex = schedule.findIndex((d) => d.day == day && d.week == week);

		if (dayIndex === -1) {
			console.error("Specified day and week not found.");
			return schedule;
		}

		const workouts = schedule[dayIndex].workouts;
		const workoutIndex = workouts.findIndex((workout) => workout.id === workoutId);

		if (workoutIndex < 0) {
			console.error("Specified workout not found.");
			return schedule;
		}

		if (workoutIndex === workouts.length - 1) {
			console.log("The workout is already at the bottom.");
			return schedule;
		}

		const temp = workouts[workoutIndex + 1];
		workouts[workoutIndex + 1] = workouts[workoutIndex];
		workouts[workoutIndex] = temp;

		const newSchedule = [...schedule];
		newSchedule[dayIndex].workouts = workouts;
		setDaysOfWeek(newSchedule);
		saveToLocal();
		return newSchedule;
	};

	const handleSaveWorkouts = () => {
		if (workoutName.trim() === "") {
			alert("Please enter a name for the workout plan.");
			return;
		}

		const updatedSavedWorkouts = {
			...savedWorkouts,
			[workoutName]: daysOfWeek,
		};
		setSavedWorkouts(updatedSavedWorkouts);
		localStorage.setItem("savedWorkouts", JSON.stringify(updatedSavedWorkouts));
		setWorkoutName("");
		alert("Workout plan saved!");
	};

	const handleLoadWorkouts = () => {
		const storedData = savedWorkouts[selectedWorkout];
		if (storedData) {
			setDaysOfWeek(storedData);
			localStorage.setItem("daysOfWeekData", storedData);
			alert("Workout plan loaded!");
		} else {
			alert("No saved workout plan found.");
		}
	};
	const clearCurrentWorkoutSchedule = () => {
		localStorage.removeItem("daysOfWeekData");
		setDaysOfWeek(initialData);
	};

	const handleShareWorkoutWithEmail = async () => {
		if (workoutName.trim() === "" || recipientEmail.trim() === "") {
			alert("Please enter a name for the workout plan and the recipient's email.");
			return;
		}

		try {
			const response = await axios.post(serverUrl + "/share-workout-with-email", {
				email: JSON.parse(localStorage.getItem("user")).email,
				workoutName: workoutName,
				workoutData: daysOfWeek,
				recipientEmail: recipientEmail,
			});

			if (response.status === 200) {
				alert("Workout plan shared successfully!");
			}
		} catch (error) {
			console.error("Failed to share workout plan:", error);
			alert("Failed to share workout plan.");
		}
	};

	const fetchSharedWorkouts = async () => {
		try {
			const response = await axios.get(serverUrl + "/shared-workouts");

			if (response.status === 200) {
				setSharedWorkouts(response.data);
			}
		} catch (error) {
			console.error("Failed to fetch shared workout plans:", error);
		}
	};

	const handleLoadSharedWorkout = () => {
		const sharedWorkout = sharedWorkouts.find((workout) => workout.id === selectedSharedWorkout);
		if (sharedWorkout) {
			const workoutData = JSON.parse(sharedWorkout.workout_data);
			setDaysOfWeek(workoutData);
			localStorage.setItem("daysOfWeekData", JSON.stringify(workoutData));
			alert("Shared workout plan loaded!");
		} else {
			alert("Please select a valid shared workout plan.");
		}
	};

	const mobileMediaQuery = "@media (max-width: 768px)"; // Adjust this breakpoint as per your needs

	return (
		<div>
			<div>
				<Fab
					color="primary"
					aria-label="menu"
					onClick={handleDrawerOpen}
					sx={{
						position: "fixed",
						bottom: 16,
						left: 16,
						zIndex: 1300,
					}}
				>
					<MenuIcon />
				</Fab>

				<Drawer
					anchor="bottom"
					open={open}
					onClose={handleDrawerClose}
					variant="persistent"
					PaperProps={{
						style: {
							background: "#f6f9fc",
							width: "80%",
							[mobileMediaQuery]: {
								width: "100%", // full width on mobile screens
							},
							height: collapsed ? 40 : "auto",
							marginLeft: "auto",
							marginRight: "auto",
							marginTop: "20px",
							overflow: "hidden",
							borderRadius: "25px 25px 0 0",
						},
					}}
				>
					<Box
						sx={{
							height: 40,
							background: "#f6f9fc",
							cursor: "pointer",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						onClick={handleTabClick}
					>
						Settings
					</Box>
					{collapsed ? null : (
						<DrawerControls
							daysOfWeek={daysOfWeek}
							currentDay={currentDay}
							previousDay={previousDay}
							nextDay={nextDay}
							selectedWeek={selectedWeek}
							setSelectedWeek={setSelectedWeek}
							selectedDifficulty={selectedDifficulty}
							setSelectedDifficulty={setSelectedDifficulty}
							getEquipmentTypes={getEquipmentTypes}
							selectedEquipment={selectedEquipment}
							setSelectedEquipment={setSelectedEquipment}
						/>
					)}
				</Drawer>
			</div>
			<div>
				<SearchAccordion
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
					selectedDifficulty={selectedDifficulty}
					setSelectedDifficulty={setSelectedDifficulty}
					selectedEquipment={selectedEquipment}
					setSelectedEquipment={setSelectedEquipment}
					getEquipmentTypes={getEquipmentTypes}
					currentExercises={currentExercises}
					addExercise={addExercise}
					isDisabled={isDisabled}
					pageNumbers={pageNumbers}
				></SearchAccordion>
				<ScheduleAccordion
					handlePageChange={handlePageChange}
					handlePersonaChange={handlePersonaChange}
					selectedPersona={persona}
					setIsScheduleRequired={setIsScheduleRequired}
					isScheduleRequired={isScheduleRequired}
					isDisabled={isDisabled}
					subscribeLink={subscribeLink}
					daysOfWeek={daysOfWeek}
					currentDay={currentDay}
					selectedWeek={selectedWeek}
					getRepsnSetsByDifficulty={getRepsnSetsByDifficulty}
					selectedDayWorkouts={selectedDayWorkouts}
					moveWorkoutUp={moveWorkoutUp}
					moveWorkoutDown={moveWorkoutDown}
					removeExercise={removeExercise}
					sendQuestion={sendQuestion}
					isLoading={isLoading}
					messages={messages}
					clearMessages={clearMessages}
				>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
						>
							<TextField
								label="Workout Plan Name"
								value={workoutName}
								onChange={(e) => setWorkoutName(e.target.value)}
								variant="outlined"
								size="small"
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							md={3}
						>
							<Button
								onClick={handleSaveWorkouts}
								variant="contained"
								color="primary"
								fullWidth
							>
								Save Plan
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							md={3}
						>
							<Button
								onClick={clearCurrentWorkoutSchedule}
								variant="contained"
								color="error"
								fullWidth
							>
								Clear Current Plan
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
						>
							<FormControl
								variant="outlined"
								size="small"
								fullWidth
							>
								<InputLabel id="select-workout-label">Saved Workout</InputLabel>
								<Select
									labelId="select-workout-label"
									value={selectedWorkout}
									onChange={(e) => setSelectedWorkout(e.target.value)}
									label="Saved Workouts"
								>
									{Object.keys(savedWorkouts).map((key) => (
										<MenuItem
											key={key}
											value={key}
										>
											{key}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							md={3}
						>
							<Button
								onClick={handleLoadWorkouts}
								variant="contained"
								color="secondary"
								fullWidth
							>
								Load Plan
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							md={3}
						>
							<Button
								onClick={deleteWorkout}
								variant="contained"
								color="error"
								fullWidth
							>
								Delete Plan
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
						>
							<TextField
								label="Recipient Email"
								value={recipientEmail}
								onChange={(e) => setRecipientEmail(e.target.value)}
								variant="outlined"
								size="small"
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							md={3}
						>
							<Button
								onClick={handleShareWorkoutWithEmail}
								variant="contained"
								color="primary"
								fullWidth
							>
								Share Plan with Email
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={8}
						></Grid>
					</Grid>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
							sm={4}
							md={4}
						>
							<FormControl
								variant="outlined"
								size="small"
								fullWidth
							>
								<InputLabel id="select-shared-workout-label">Select Shared Workout</InputLabel>
								<Select
									labelId="select-shared-workout-label"
									value={selectedSharedWorkout}
									onChange={(e) => setSelectedSharedWorkout(e.target.value)}
									label="Select Shared Workout"
								>
									{sharedWorkouts.map((workout) => (
										<MenuItem
											key={workout.id}
											value={workout.id}
										>
											{workout.workout_name} by {workout.email}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							md={3}
						>
							<Button
								onClick={handleLoadSharedWorkout}
								variant="contained"
								color="secondary"
								fullWidth
							>
								Load Shared Plan
							</Button>
						</Grid>
					</Grid>
				</ScheduleAccordion>
				<ChatAccordion
					workoutMode={workoutMode}
					setWorkoutMode={setWorkoutMode}
					currentDayWorkouts={selectedDayWorkouts.workouts}
					completedExercises={completedExercises}
					handleCompleteExercise={handleCompleteExercise}
					handleFinishWorkout={handleFinishWorkout}
					serverUrl={serverUrl}
				/>
			</div>
		</div>
	);
};

export default GymBro;
