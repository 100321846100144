// components/StartWorkout.js
import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import axios from "axios";

const StartWorkout = () => {
    const [currentDayWorkouts, setCurrentDayWorkouts] = useState([]);
    const [completedExercises, setCompletedExercises] = useState([]);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        const loadWorkoutsFromLocalStorage = () => {
            const storedWorkouts = JSON.parse(localStorage.getItem("daysOfWeekData"));

            // Debugging logs
            console.log("Stored Workouts:", storedWorkouts);

            const currentDayIndex = new Date().getDay();
            const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const today = days[currentDayIndex];

            // Debugging logs
            console.log("Today:", today);

            if (storedWorkouts) {
                const currentWeek = 1; // You can dynamically determine the week if necessary
                const todayWorkouts = storedWorkouts.find(item => item.day === today && item.week === currentWeek);

                // Debugging logs
                console.log("Today's Workouts:", todayWorkouts);

                if (todayWorkouts) {
                    setCurrentDayWorkouts(todayWorkouts.workouts);
                } else {
                    console.warn("No workouts found for today.");
                }
            } else {
                console.warn("No workouts found in local storage.");
            }
        };

        loadWorkoutsFromLocalStorage();
    }, []);

    const handleCompleteExercise = (exerciseId) => {
        setCompletedExercises((prev) => [...prev, exerciseId]);
    };

    const handleFinishWorkout = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            await axios.post(`${apiUrl}/save-completed-workouts`, {
                completedExercises,
            });
            setIsComplete(true);
        } catch (error) {
            console.error("Failed to save completed workouts:", error);
        }
    };

    const fade = useSpring({
        opacity: isComplete ? 1 : 0,
        transform: isComplete ? "scale(1)" : "scale(0.9)",
    });

    return (
        <div style={{ padding: "20px" }}>
            <h1>Start Workout</h1>
            <div style={{ margin: "20px 0" }}>
                {currentDayWorkouts.map((exercise, index) => (
                    <ExerciseCard
                        key={index}
                        exercise={exercise}
                        isCompleted={completedExercises.includes(exercise.id)}
                        onComplete={handleCompleteExercise}
                    />
                ))}
            </div>
            {completedExercises.length === currentDayWorkouts.length && (
                <animated.div style={fade}>
                    <button onClick={handleFinishWorkout} style={finishButtonStyle}>
                        Finish Workout
                    </button>
                </animated.div>
            )}
        </div>
    );
};

const finishButtonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4caf50",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
};

const ExerciseCard = ({ exercise, isCompleted, onComplete }) => {
    const fade = useSpring({
        opacity: isCompleted ? 1 : 0,
        transform: isCompleted ? "scale(1)" : "scale(0.9)",
    });

    const handleCompleteClick = () => {
        if (!isCompleted) {
            onComplete(exercise.id);
        }
    };

    return (
        <div style={{ marginBottom: "10px", border: "1px solid #ddd", padding: "10px", borderRadius: "5px" }}>
            <h3>{exercise.name}</h3>
            <p>{exercise.instructions.join(" ")}</p>
            {!isCompleted && (
                <button onClick={handleCompleteClick} style={completeButtonStyle}>
                    Complete
                </button>
            )}
            {isCompleted && (
                <animated.div style={fade}>
                    <p style={{ color: "green" }}>Completed!</p>
                </animated.div>
            )}
        </div>
    );
};

const completeButtonStyle = {
    padding: "5px 10px",
    fontSize: "14px",
    backgroundColor: "#2196f3",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
};

export default StartWorkout;
