import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
function Copyright(props) {
  return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link
				color="inherit"
				href="/"
			>
				GymGenie
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

function Register() {
	const [formData, setFormData] = useState({ username: "", password: "" });
	const [message, setMessage] = useState("");
	const [errors, setErrors] = useState({ username: "", password: "" });
	const history = useNavigate();

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData({ ...formData, [name]: value });

		if (name === "username" && !isValidEmail(value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				username: "Invalid email format.",
			}));
		} else if (name === "password" && !isValidPassword(value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				password: "Password must be at least 8 characters long.",
			}));
		} else {
			setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
		}
	};

	const isValidEmail = (email) => {
		const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return re.test(String(email).toLowerCase());
	};

	const isValidPassword = (password) => {
		return password.length >= 8;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isValidEmail(formData.username) || !isValidPassword(formData.password)) {
			return; // Don't proceed if there are validation errors.
		}

		try {
			if (formData.username.length > 0 && formData.password.length) {
				const response = await axios.post(process.env.REACT_APP_BACK_END_URL + "/register", formData);
				localStorage.setItem("token", response.data.token);
				localStorage.setItem("user", JSON.stringify(response.data.userDetails));
				history("/gymbro");
			}
		} catch (error) {
			setMessage(error.msg);
		}
	};
  return (
		<Grid
			container
			component="main"
			sx={{ height: "100vh" }}
		>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
					backgroundRepeat: "no-repeat",
					backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						textAlign: "center",
						color: "#fff", // You can adjust this to any color that makes the text visible
					}}
				>
					<Typography
						variant="h2"
						component="div"
					>
						Unlock Your Potential
					</Typography>
					<Typography
						variant="h5"
						component="div"
					>
						Join our fitness community and transform your life, one workout at a time.
					</Typography>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
			>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>{/* <LockOutlinedIcon /> */}</Avatar>
					<Typography
						component="h1"
						variant="h5"
					>
						Register
					</Typography>
					<Box
						component="form"
						noValidate
						onSubmit={handleSubmit}
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="username"
							error={!!errors.username}
							helperText={errors.username}
							value={formData.username}
							onChange={handleChange}
							autoComplete="email"
							autoFocus
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							error={!!errors.password}
							helperText={errors.password}
							value={formData.password}
							onChange={handleChange}
							id="password"
							autoComplete="current-password"
						/>
						<FormControlLabel
							control={
								<Checkbox
									value="remember"
									color="primary"
								/>
							}
							label="Remember me"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							disabled={!isValidEmail(formData.username) || !isValidPassword(formData.password)}
							sx={{ mt: 3, mb: 2 }}
						>
							Register
						</Button>{" "}
						{message.message}
						<Grid container>
							<Grid
								item
								xs
							>
								<Link
									href="#"
									variant="body2"
								>
									Forgot password?
								</Link>
							</Grid>
							<Grid item>
								<Link
									href="#"
									variant="body2"
								>
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
						<Copyright sx={{ mt: 5 }} />
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}

export default Register;
