// src/Chat.js
import React from "react";
import { Button, Box, Card, CardContent, Typography } from "@mui/material";

function Chat({ messages, clearMessages }) {
  const removeMessagesFromLocal = async () => {
    await clearMessages();
  };

  return (
    <Box
      sx={{
        border: "none",
        padding: "10px",
        overflowY: "auto",
        height: "400px",
        bgcolor: "#e0f7fa", // Light cyan background for the chat container
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={removeMessagesFromLocal}
        sx={{ mb: 2 }}
      >
        Clear Chat
      </Button>
      {messages &&
        messages.map((msg, index) => (
          <Card
            key={index}
            sx={{
              margin: "10px 0",
              padding: "10px",
              bgcolor: msg.role === "user" ? "#ffecb3" : "#ffccbc", // Light yellow for user, light orange for assistant
            }}
          >
            <CardContent>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  color: msg.role === "user" ? "#f57f17" : "#d32f2f", // Vibrant orange for user, vibrant red for assistant
                  fontWeight: 'bold',
                }}
              >
                {msg.role}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                dangerouslySetInnerHTML={{ __html: msg.content }}
              />
            </CardContent>
          </Card>
        ))}
    </Box>
  );
}

export default Chat;
