import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const url = process.env.REACT_APP_BACK_END_URL;
function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link
				color="inherit"
				href="https://mui.com/"
			>
				GymGenie
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

// TODO remove, this demo shouldn't need to reset the theme.

function Login() {
	const history = useNavigate();

	const [formData, setFormData] = useState({ username: "", password: "" });
	const [message, setMessage] = useState("");

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		console.log("handling submit");
		e.preventDefault();
		try {
			console.log("process.env.REACT_APP_BACK_END_URL ::> , ", url);
			const response = await axios.post(url + "/login", formData, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			localStorage.setItem("token", response.data.token);
			localStorage.setItem("user", JSON.stringify(response.data.userDetails));
			setMessage(response.data.message);
			sessionStorage.setItem("user", response.data);
			console.log("login deets:> ", response.data);
			history("/gymbro"); // Navigate to the About page
		} catch (error) {
			console.log(error);
			setMessage("Login failed");
		}
	};

	return (
		<Grid
			container
			component="main"
			sx={{ height: "100vh" }}
		>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
					backgroundRepeat: "no-repeat",
					backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						textAlign: "center",
						color: "#fff", // You can adjust this to any color that makes the text visible
					}}
				>
					<Typography
						variant="h2"
						component="div"
					>
						Unlock Your Potential
					</Typography>
					<Typography
						variant="h5"
						component="div"
					>
						Join our fitness community and transform your life, one workout at a time.
					</Typography>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
			>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>{/* <LockOutlinedIcon /> */}</Avatar>
					<Typography
						component="h1"
						variant="h5"
					>
						Sign in
					</Typography>
					<Box
						component="form"
						noValidate
						onSubmit={handleSubmit}
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							value={formData.username}
							onChange={handleChange}
							name="username"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							value={formData.password}
							onChange={handleChange}
							id="password"
							autoComplete="current-password"
						/>
						<FormControlLabel
							control={
								<Checkbox
									value="remember"
									color="primary"
								/>
							}
							label="Remember me"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign In
						</Button>{" "}
						<div style={{ marginRight: "auto", marginLeft: "auto", color: "red" }}>{message}</div>
						<Grid container>
							<Grid
								item
								xs
							>
								<Link
									href="#"
									variant="body2"
								>
									Forgot password?
								</Link>
							</Grid>
							<Grid item>
								<Link
									href="/register"
									variant="body2"
								>
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
						<Copyright sx={{ mt: 5 }} />
					</Box>
				</Box>
			</Grid>
		</Grid>

		// <Container component="main" maxWidth="xs">
		// 	<CssBaseline />
		// 	<Box
		// 		sx={{
		// 			marginTop: 8,
		// 			display: "flex",
		// 			flexDirection: "column",
		// 			alignItems: "center",
		// 		}}>
		// 		<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
		// 			{/* <LockOutlinedIcon /> */}
		// 		</Avatar>
		// 		<Typography component="h1" variant="h5">
		// 			Sign in
		// 		</Typography>

		// 		<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
		// 			<TextField
		// 				margin="normal"
		// 				required
		// 				fullWidth
		// 				id="email"
		// 				label="Email Address"
		// 				value={formData.username}
		// 				onChange={handleChange}
		// 				name="username"
		// 				autoComplete="email"
		// 				autoFocus
		// 			/>
		// 			<TextField
		// 				margin="normal"
		// 				required
		// 				fullWidth
		// 				name="password"
		// 				label="Password"
		// 				type="password"
		// 				value={formData.password}
		// 				onChange={handleChange}
		// 				id="password"
		// 				autoComplete="current-password"
		// 			/>
		// 			<FormControlLabel
		// 				control={<Checkbox value="remember" color="primary" />}
		// 				label="Remember me"
		// 			/>
		// 			<Button
		// 				type="submit"
		// 				fullWidth
		// 				variant="contained"
		// 				sx={{ mt: 3, mb: 2 }}>
		// 				Sign In
		// 			</Button>
		// 			<Grid container>
		// 				<Grid item xs>
		// 					<Link href="#" variant="body2">
		// 						Forgot password?
		// 					</Link>
		// 				</Grid>
		// 				<Grid item>
		// 					<Link href="/register" variant="body2">
		// 						{"Don't have an account? Sign Up"}
		// 					</Link>
		// 				</Grid>
		// 			</Grid>
		// 		</Box>
		// 	</Box>
		// 	{message}
		// 	<Copyright sx={{ mt: 8, mb: 4 }} />
		// </Container>

		// <div>
		// 	<h2>Login</h2>
		// 	<form onSubmit={handleSubmit}>
		// 		<div>
		// 			<label>Username: </label>
		// 			<input
		// 				type="text"
		// 				name="username"
		// 				value={formData.username}
		// 				onChange={handleChange}
		// 				required
		// 			/>
		// 		</div>
		// 		<div>
		// 			<label>Password: </label>
		// 			<input
		// 				type="password"
		// 				name="password"
		// 				value={formData.password}
		// 				onChange={handleChange}
		// 				required
		// 			/>
		// 		</div>
		// 		<button type="submit">Login</button>
		// 	</form>
		// 	<p>{message}</p>
		// </div>
	);
}

export default Login;

// import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// // TODO remove, this demo shouldn't need to reset the theme.

// const defaultTheme = createTheme();

// export default function SignIn() {
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     console.log({
//       email: data.get('email'),
//       password: data.get('password'),
//     });
//   };

//   return (

//   );
// }
