import React from "react";
import PhotoGallery from "./PhotoGallery";
import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ExerciseCard = styled(Card)`
  margin: 20px;
  background-color: #fdf7e3; /* Light pastel yellow */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  vertical-align: top;
  box-sizing: border-box;
`;

const ExerciseDetails = styled.div`
  padding: 20px;
`;

const ExerciseImage = styled.div`
  flex-shrink: 0;
  width: 150px;
`;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));

function ExerciseList({ exercises, expand, applyToDay, isDisabled }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedExercises, setExpandedExercises] = React.useState({});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [value, setValue] = React.useState(null);

  const handleExpandClick = (exerciseName) => {
    setExpandedExercises((prevExpanded) => ({
      ...prevExpanded,
      [exerciseName]: !prevExpanded[exerciseName],
    }));
  };

  const applyToSelectedDay = (exercise) => {
    if (exercise.isSelected) {
      exercise.isSelected = !exercise.isSelected;
    } else {
      exercise.isSelected = true;
    }
    applyToDay(exercise);
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", padding: "0px" }}>
      {exercises.map((exercise, index) => (
        <Card
          key={index}
          sx={{
            minWidth: isMobile ? '100%' : 300,
            maxWidth: isMobile ? '100%' : 300,
            margin: isMobile ? '10px 0' : '10px',
            backgroundColor: "#ECEFF1",
            borderRadius: "10px", // Add rounded corners
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                <strong>{exercise.name}</strong>
              </Typography>
            }
            subheader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  {exercise.level}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => applyToSelectedDay(exercise)}
                  disabled={isDisabled}
                  sx={{ borderRadius: "20px" }} // Rounded button
                >
                  <AddIcon color="primary" />
                  Add Workout
                </Button>
              </div>
            }
          />
          <CardMedia>
            <PhotoGallery photos={exercise.images} />
          </CardMedia>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              <p>
                <strong>Force:</strong> {exercise.force}
                <br />
                <strong>Level:</strong> {exercise.level}
                <br />
                <strong>Mechanic:</strong> {exercise.mechanic}
                <br />
                <strong>Equipment:</strong> {exercise.equipment}
                <br />
                <strong>Category:</strong> {exercise.category}
                <br />
                <strong>Primary Muscles:</strong> {exercise.primaryMuscles}
                <br />
                <strong>Secondary Muscles:</strong> {exercise.secondaryMuscles}
              </p>
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
            <ExpandMore
              expand={expandedExercises[exercise.name]}
              onClick={() => handleExpandClick(exercise.name)}
              aria-expanded={expandedExercises[exercise.name]}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse
            in={expandedExercises[exercise.name]}
            timeout="auto"
            unmountOnExit
          >
            <CardContent>
              <Typography paragraph>
                <h3>Instructions:</h3>
                <ol>{exercise.instructions}</ol>
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  );
}

export default ExerciseList;
