// Profile.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText
} from '@mui/material';

function ProfilePage() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the subscriptions from your server
    fetch('/subscriptions')
      .then(response => response.json())
      .then(data => {
        setSubscriptions(data);
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <Typography variant="h4">Your Subscriptions</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {subscriptions.map(sub => (
            <ListItem key={sub.id}>
              <ListItemText primary={sub.productName} secondary={`Status: ${sub.status}`} />
              {sub.status === "active" && (
                <Button variant="contained" color="secondary">
                  Cancel
                </Button>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
}

export default ProfilePage;
